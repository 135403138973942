import React from "react";
import {Link, useParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {BACKENDURL} from "../Constants";
import Sidebar from "../components/Sidebar";

const CATEGORY = gql`
query GetCategory($id: ID!) {
  category(id: $id ) {
   data {
      id
      attributes {
        subcategories(sort: "priority:asc") {
          data {
            id
            attributes {
              name
              image {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        name
        richText
        items(sort: "priority:asc") {
          data {
            id
            attributes {
              title
              price
              pictures {
                data {
                  attributes {
                    url
                  }
                }
              }
              categories {
                data {
                  id,
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export default function Category() {
    const {id} = useParams()
    const {data, loading, error} = useQuery(CATEGORY, {
        variables: {id: id}
    })
    if (loading) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Načítám položky...</h2>
            </div>
        </div>)
    if (error) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Nastala chyba pří načítání dat, zkuste to prosím později...</h2>
            </div>
        </div>
    )
    const isDataPresent = data.category.data.attributes.items.data.length !== 0
    const isSubcategoryPresent = data.category.data.attributes.subcategories.data.length !== 0

    function NoData() {
        return (
            <div className="marginTop">
                <div className="main_title">
                    <h2>Tato kategorie zatím neobsahuje žádné položky</h2>
                </div>
            </div>
        )
    }


    function HasData() {
        return (
            <div className="marginTop">
                <Sidebar/>

                <section className="feature_area ">
                    <div className="main_title">
                        <h2 className="text-uppercase">{data.category.data.attributes.name}</h2>
                        {data.category.data.attributes.richText.map((paragraph, index) => (
                            <p key={index}
                               className="main_title category_description"> {paragraph.children[0].text} </p>
                        ))}
                    </div>
                    <div className="container">
                        <div className="row feature_inner">
                            {data.category.data.attributes.items.data.map(item => (
                                <div key={item.id} className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="feature_item">
                                        <Link to={`/items/${item.id}`}>

                                            <img className="image_style" alt="item-image"
                                                 src={BACKENDURL + item.attributes.pictures.data[0].attributes.url}/>
                                            <div className="hover">
                                                <h4>{item.attributes.title}</h4>
                                                <h4>{numberWithSpaces(item.attributes.price)} Kč</h4>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                            {data.category.data.attributes.subcategories.data.map(subcategory => (
                                <div key={subcategory.id} className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="feature_item">
                                        <Link to={`/category/${subcategory.id}`}>

                                            <img className="image_style" alt="subcategory-image"
                                                 src={BACKENDURL + subcategory.attributes.image.data.attributes.url}/>
                                            <div className="hover">
                                                <h4>{subcategory.attributes.name}</h4>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    function renderContent(valueOfIsDataPresent, valueOfIsSubcategoryPresent) {
        if (valueOfIsDataPresent || valueOfIsSubcategoryPresent) {
            return <HasData/>
        } else {
            return <NoData/>
        }
    }

    return (
        renderContent(isDataPresent, isSubcategoryPresent)
    )
}