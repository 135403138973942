import React from 'react';
import YouTube from 'react-youtube';

const YoutubeVideo = ({videoId}) => {
    const opts = {
        height: '780',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    const onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    };

    return (
        <div className=" col-lg-12 col-md-12">
            <YouTube videoId={videoId} opts={opts} onReady={onReady}/>;
        </div>
    )
};

export default YoutubeVideo;