export default function BuildingWork() {
    return (
        <section className="projects_area">
            <div className="main_title" style={{textAlign: "left"}}>
                <h3>Komplexní stavební práce na klíč</h3>
                <p>Hledáte spolehlivého partnera pro realizaci staveb na klíč, opravy, výměnu střech, omítky,
                    zateplování, demolice, rekonstrukce nebo zemní práce? Jsme tu pro vás! Nabízíme kompletní stavební
                    služby, které pokrývají všechny vaše potřeby, a to včetně oprav kanalizací, terénních úprav zahrad a
                    správy a údržby průmyslových areálů.</p>
                <h3>Naše služby zahrnují:</h3>
                <ul className="list-unstyled">
                    <li><b>Realizace staveb na klíč:</b> Kompletní výstavba domů a komerčních objektů přesně podle
                        vašich představ.
                    </li>
                    <li><b>Opravy staveb:</b> Rychlá a efektivní řešení pro všechny typy stavebních závad.</li>
                    <li><b>Výměna střech:</b> Profesionální výměna střech s využitím kvalitních materiálů pro dlouhou
                        životnost.
                    </li>
                    <li><b>Omítky a zateplování:</b> Zvýšení energetické účinnosti vašeho domu a zlepšení jeho vzhledu.
                    </li>
                    <li><b>Demolice a rekonstrukce:</b>
                        Bezpečné a efektivní demolice, stejně jako pečlivé rekonstrukce
                        starších budov.
                    </li>
                    <li><b>Zemní práce:</b> Vše od výkopů pro základy až po úpravy terénu.</li>
                    <li><b>Opravy kanalizací:</b> Spolehlivá oprava a údržba kanalizačních systémů pro bezproblémový
                        chod
                        vaší
                        domácnosti nebo firmy.
                    </li>
                    <li><b>Terénní úpravy zahrad:</b> Profesionální úpravy terénu a zahradních ploch pro krásný a
                        funkční
                        exteriér.
                    </li>
                    <li><b>Správa a údržba průmyslových areálů:</b> Kompletní správa a údržba průmyslových areálů,
                        zajišťující
                        jejich efektivní a bezproblémový provoz.
                    </li>
                </ul>
                <h3>Proč si vybrat nás?</h3>
                <ul className="list-unstyled">
                    <li><b>Dlouholeté zkušenosti:</b> Naše firma má bohaté zkušenosti ve stavebním průmyslu a za námi
                        stojí
                        mnoho úspěšně dokončených projektů.
                    </li>
                    <li><b>Moderní technika:</b> Disponujeme nejnovější technikou pro všechny druhy zemních a stavebních
                        prací,
                        což nám umožňuje rychle a efektivně plnit vaše požadavky.
                    </li>
                    <li><b>Profesionální přístup:</b> Naši odborníci jsou vždy připraveni naslouchat vašim potřebám a
                        poskytovat služby na nejvyšší úrovni.
                    </li>
                </ul>
                <p>S námi získáte jistotu kvalitního provedení a spokojenosti s výsledkem. Kontaktujte nás ještě dnes a
                    zjistěte, jak vám můžeme pomoci uskutečnit vaše stavební sny!</p>
            </div>
        </section>
    )
}