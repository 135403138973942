import React from "react";
import {gql, useQuery} from "@apollo/client";
import Slider from "react-slick";
import {BACKENDURL} from "../Constants";
import {Link} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ITEMS = gql`
query getHighlightedItems {
  items(filters: {highlight: {eq: true} } ) {
    data {
      id
      attributes {
        title
        pictures {
          data {
            attributes {
              url
            }
          }
        }
        price
      }
    }
  }
}
`


function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


export default function HighlightBar() {
    const {loading, error, data} = useQuery(ITEMS)

    const settings = {
        dots: true,
        infinite: true,
        centerPadding: "30px",
        slidesToShow: 4,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    if (loading) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Načítám položky...</h2>
            </div>
        </div>)
    if (error) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Nastala chyba pří načítání dat, zkuste to prosím později...</h2>
            </div>
        </div>
    )

    const isDataPresent = data.items.data.length !== 0

    function NoData() {
        return (<div></div>)
    }

    function HasData() {
        return (

            <div className="container" style={{width: '98%'}}>
                <div className="main_title">
                    <h2>Nové položky</h2>
                </div>
                <div className="slider-container">
                    <Slider {...settings}>
                        {data.items.data.map(item => (
                            <div key={item.id} className="m-1">
                                <div className="feature_item">
                                    <Link to={`/items/${item.id}`}>

                                        <img className="image_style" alt="item-image"
                                             src={BACKENDURL + item.attributes.pictures.data[0].attributes.url}/>
                                        <div className="hover">
                                            <h4>{item.attributes.title}</h4>
                                            <h4>{numberWithSpaces(item.attributes.price)} Kč</h4>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        )
    }

    function renderContent(isDataPresent) {
        if (isDataPresent) {
            return <HasData/>
        } else {
            return <NoData/>
        }
    }

    return (renderContent(isDataPresent))
}