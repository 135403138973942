import React from "react";
import {Link} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {BACKENDURL} from "../Constants";
import Sidebar from "../components/Sidebar";
import HighlightBar from "../components/HighlightBar";

const CATEGORY = gql`
query GetCategories {
  categories(sort: "priority:asc") {
    data {
      id
      attributes {
        name,
        isSubcategory,
        image {
          data{
            id,
            attributes{
              url
            }
          }
        }
      }
    }
  }
}
`
export default function Categories() {
    const {data, loading, error} = useQuery(CATEGORY)
    if (loading) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Načítám položky...</h2>
            </div>
        </div>)
    if (error) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Nastala chyba pří načítání dat, zkuste to prosím později...</h2>
            </div>
        </div>
    )

    return (
        <div className="marginTop">
            <Sidebar/>

            <section className="feature_area ">
                <div className="main_title">
                    <h2>Vyberte si z těchto kategorií</h2>
                </div>
                <div className="leftSpace container ">
                    <div className="row feature_inner">


                        {data.categories.data.filter(category => !category.attributes.isSubcategory).map(category => (
                            <div key={category.id} className="col-xl-3 col-lg-4 col-md-6">
                                <div className="feature_item">
                                    <Link to={`/category/${category.id}`}>

                                        <img className="image_style" alt="obrazek kategorie"
                                             src={BACKENDURL + category.attributes.image.data.attributes.url}/>
                                        <div className="hover">
                                            <h4>{category.attributes.name}</h4>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <HighlightBar isItems={true}/>
        </div>

    )
}