import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

import ItemDetail from "./pages/ItemDetail";
import Category from "./pages/Category";
import SiteHeader from "./components/SiteHeader";
import LandPage from "./pages/LandPage";
import Categories from "./pages/Categories";
import Footer from "./components/Footer"
import SiteHeaderNoBG from "./components/SiteHeaderNoBG"
import {BACKENDURL} from "./Constants";
import ScrollToTop from "./hooks/scrollToTop";
import BuildingWork from "./pages/BuildingWork";
import RentalCategories from "./pages/RentalCategories";
import RentItemDetail from "./pages/RentItemDetail";
import RentalCategory from "./pages/RentalCategory";

//apollo client
const client = new ApolloClient({
    uri: `${BACKENDURL}/graphql`,
    cache: new InMemoryCache()
})

function App() {
    return (
        <Router>
            <ApolloProvider client={client}>

                <div className="App">
                    {window.location.pathname !== "/" ? <SiteHeader/> : <SiteHeaderNoBG/>}
                    <ScrollToTop/>
                    <Routes>
                        <Route exact path="/" element={<LandPage/>}/>
                        <Route path="/items/:id" element={<ItemDetail/>}/>
                        <Route path="/category/:id" element={<Category/>}/>
                        <Route path="/categories" element={<Categories/>}/>
                        <Route path="/building" element={<BuildingWork/>}/>
                        <Route path="/rentalItems/:id" element={<RentItemDetail/>}/>
                        <Route path="/rentalCategory/:id" element={<RentalCategory/>}/>
                        <Route path="/rentalCategories" element={<RentalCategories/>}/>
                    </Routes>
                    <Footer/>
                </div>
            </ApolloProvider>
        </Router>
    );
}

export default App;
