import React from "react";

import logo from "../assets/img/logo.png"

export default function Items() {

    return (
        <footer className="footer_area p_120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-4">
                        <img src={logo} alt="" width="250"
                             height="150"/>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                        <div className="contact_info">
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6"><a href="/contact.html">KONTAKT</a></h6>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">NAPIŠTE NÁM</h6>
                                <p className="col-lg-6 col-xl-6"><a href="mailto: info@bumis.cz">info@bumis.cz </a></p>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">ZAVOLEJTE NÁM</h6>
                                <p className="col-lg-6 col-xl-6">+420 605 965 293</p>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">POBOČKA SENOŽATY</h6>
                                <p className="col-lg-6 col-xl-6">BUMIS, spol. s r.o. <br/> Senožaty 201 <br/> 394 56
                                    Senožaty</p>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">POBOČKA POČÁTKY</h6>
                                <p className="col-lg-6 col-xl-6">BUMIS, spol. s r.o. <br/> Horní 42 <br/> 394 64 Počátky
                                </p>
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                        <div className="contact_info">
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">IČO:</h6>
                                <p className="col-lg-6 col-xl-6">14346095</p>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">DIČ:</h6>
                                <p className="col-lg-6 col-xl-6">CZ14346095</p>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">ID datové schránky:</h6>
                                <p className="col-lg-6 col-xl-6">mgmnyxf</p>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">CZK:</h6>
                                <p className="col-lg-6 col-xl-6"> Č. účtu: 2702630479/2010</p>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">IBAN:</h6>
                                <p className="col-lg-6 col-xl-6">CZ81 2010 0000 0027 0263 0479</p>
                            </div>
                            <div className="info_item">
                                <h6 className="col-lg-6 col-xl-6">BIC/SWIFT:</h6>
                                <p className="col-lg-6 col-xl-6">FIOBCZPPXXX</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

