import '../css/responsive.css'
import {useState} from "react";


export default function ContactForm({isWithDays}) {
    const [isVisible, setIsVisible] = useState(false);

    function sendEmail() {

        var form = document.contactMe;

        form.setAttribute('action', `https://formsubmit.co/info@bumis.cz`)
        document.getElementById("submitButton").click();

        document.getElementById("reply").innerHTML = "Posílám email, prosím čekejte...";

    }

    if (isWithDays) {
        return (
            <div>
                <a style={{fontSize: '26px', fontWeight: 'bold'}} className="submit_btn"
                   onClick={() => setIsVisible(!isVisible)}>Nezávazná poptávka</a>
                {isVisible && (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 posts-list">
                                <div>
                                    <h2 id="reply">Kontaktní formulář</h2>
                                    <form action="https://formsubmit.co/" name="contactMe" method="POST">
                                        <div>
                                            <h3>Doba půjčení (dny)</h3>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group email">
                                                <input type="number" className="form-control myForm" name="DobaPujceni"
                                                       required="required"/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Jméno a příjmení</h3>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group email">
                                                <input type="text" className="form-control myForm" name="Jmeno"
                                                       required="required"/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Email</h3>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group email">
                                                <input type="email" className="form-control myForm" name="email"
                                                       required="required"/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Telefon</h3>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group email">
                                                <input type="text" className="form-control myForm" name="Telefon"
                                                       required="required"/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Dotaz</h3>
                                        </div>
                                        <div className="form-group">
                            <textarea className="form-control mb-10 myForm" rows="5" name="message"
                                      required="required" id="message"></textarea>
                                        </div>
                                        <button type="submit" id="submitButton" style={{display: 'none'}}></button>
                                        <a onClick={sendEmail} className="primary-btn submit_btn">Odeslat nezávaznou
                                            poptávku</a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <div>
                <a style={{fontSize: '26px', fontWeight: 'bold'}} className="submit_btn"
                   onClick={() => setIsVisible(!isVisible)}>Nezávazná poptávka</a>
                {isVisible && (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 posts-list">
                                <div>
                                    <h2 id="reply">Kontaktní formulář</h2>
                                    <form action="https://formsubmit.co/" name="contactMe" method="POST">
                                        <div>
                                            <h3>Jméno a příjmení</h3>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group email">
                                                <input type="text" className="form-control myForm" name="Jmeno"
                                                       required="required"/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Email</h3>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group email">
                                                <input type="email" className="form-control myForm" name="email"
                                                       required="required"/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Telefon</h3>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group email">
                                                <input type="text" className="form-control myForm" name="Telefon"
                                                       required="required"/>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Dotaz</h3>
                                        </div>
                                        <div className="form-group">
                            <textarea className="form-control mb-10 myForm" rows="5" name="message"
                                      required="required" id="message"></textarea>
                                        </div>
                                        <button type="submit" id="submitButton" style={{display: 'none'}}></button>
                                        <a onClick={sendEmail} className="primary-btn submit_btn">Odeslat nezávaznou
                                            poptávku</a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        )
    }
}
