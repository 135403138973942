import React, {useState} from "react";
import {gql, useQuery} from "@apollo/client";
import '@coreui/coreui/dist/css/coreui.min.css'
import {
    CCloseButton,
    CContainer,
    CNavbar,
    CNavbarNav,
    CNavbarToggler,
    CNavItem,
    CNavLink,
    COffcanvas,
    COffcanvasBody,
    COffcanvasHeader,
    COffcanvasTitle
} from "@coreui/react";

const CATEGORIES = gql`
query getCategories {
  rentalCategories{
    data{
      id
      attributes {
        name,
        items{
          data{
            id
          }
        }
      }
    }
  }
}
`
export default function SidebarRental() {

    const {loading, error, data} = useQuery(CATEGORIES)
    const [visible, setVisible] = useState(false)

    if (loading) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Načítám položky...</h2>
            </div>
        </div>)
    if (error) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Nastala chyba pří načítání dat, zkuste to prosím později...</h2>
            </div>
        </div>
    )


    return (
        <CNavbar style={{position: "fixed", right: "0", color: "#F8B600"}} className="bg-body-tertiary bg-transparent "
                 placement="sticky-top">
            <CContainer>
                <COffcanvas id="offcanvasNavbar" placement="end" portal={false} visible={visible}
                            onHide={() => setVisible(false)}>
                    <COffcanvasHeader>
                        <COffcanvasTitle>Kategorie</COffcanvasTitle>
                        <CCloseButton className="text-reset" onClick={() => setVisible(false)}/>
                    </COffcanvasHeader>
                    <COffcanvasBody>
                        <CNavbarNav>
                            {data.rentalCategories.data.map(category => (
                                <CNavItem key={category.id}
                                          href={`/category/${category.id}`}> {category.attributes.name}</CNavItem>
                            ))}
                            <CNavItem>
                                <CNavLink href="/categories">Všechny kategorie</CNavLink>
                            </CNavItem>

                        </CNavbarNav>
                    </COffcanvasBody>
                </COffcanvas>
                <CNavbarToggler
                    aria-controls="offcanvasNavbar"
                    aria-label="Toggle navigation"
                    onClick={() => setVisible(!visible)}
                    style={{color: "#F8B600", backgroundColor: "rgba(0,0,0,0.4)"}}
                >Kategorie </CNavbarToggler>
            </CContainer>
        </CNavbar>
    )
}