import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {BACKENDURL} from "../Constants";
import Sidebar from "../components/SidebarRental";
import YoutubeVideo from "../components/YoutubeVideo";
import '../css/responsive.css'
import ContactForm from "../components/ContactForm";

const ITEM = gql`
query GetItem($id: ID!) {
  rentelItem(id: $id) {
   data {
    id
    attributes {
      title
      price
      pictures {
        data{
          id
          attributes {
            url
          }
        }
      }
      parameters
      richText
      youtubeId
    }
   }
  }
}
`

function priceWithDPH(x) {
    return Math.round(x * 1.21)
}

const ImageCarousel = ({data}) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setIsFullScreen(true);
    };

    const handleCloseFullScreen = () => {
        setIsFullScreen(false);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleCloseFullScreen();
            }
        };

        if (isFullScreen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullScreen]);


    return (
        <>
            <div className="col-lg-4 col-md-6">
                <Carousel useKeyboardArrows={true} showThumbs={false} onClickItem={handleImageClick}>
                    {data.rentelItem.data.attributes.pictures.data.map((picture, index) => (
                        <div key={index} onClick={() => handleImageClick(index)} className="slide">
                            <img alt="sample_file" src={BACKENDURL + picture.attributes.url} key={picture.id}/>
                        </div>
                    ))}
                </Carousel>
            </div>
            {isFullScreen && (
                <div className="fullscreen-overlay">
                    <button className="exit-button" onClick={handleCloseFullScreen}>Zavřít</button>
                    <Carousel useKeyboardArrows={true} showThumbs={false} selectedItem={selectedImageIndex}>
                        {data.rentelItem.data.attributes.pictures.data.map((picture, index) => (
                            <div key={index} className="slide">
                                <img style={{objectFit: "contain"}} alt="sample_file"
                                     src={BACKENDURL + picture.attributes.url} key={picture.id}/>
                            </div>
                        ))}
                    </Carousel>
                </div>

            )}
        </>
    );
}

export default function ItemDetail() {
    const {id} = useParams()
    const {data, loading, error} = useQuery(ITEM, {
        variables: {id: id}
    })
    if (loading) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Načítám položky...</h2>
            </div>
        </div>)
    if (error) return (
        <div className="marginTop">
            <div className="main_title">
                <h2>Nastala chyba pří načítání dat, zkuste to prosím později...</h2>
            </div>
        </div>
    )


    return (
        <div className="marginTop">
            <Sidebar/>

            <section className="projects_area">
                <div className="main_title text-uppercase">
                    <h3>{data.rentelItem.data.attributes.title}</h3>
                </div>
            </section>
            <div className="container">
                <div className="row feature_inner">

                    <ImageCarousel data={data}/>

                    <div className="col-lg-8 col-md-6">
                        <div className="m-3">
                            <h3 className="mt-3 text-uppercase">{data.rentelItem.data.attributes.title}</h3>
                            {data.rentelItem.data.attributes.richText.map((paragraph, index) => (
                                <p key={index} className="mt-3">{paragraph.children[0].text}</p>
                            ))}
                            <table className="table table-dark">
                                <tbody>
                                <tr className="table-active">
                                    <th scope="row"></th>
                                    <th scope="row">cena</th>
                                    <th scope="row">cena s DPH</th>
                                </tr>

                                </tbody>
                                <tbody>
                                <tr>
                                    <th className="table-active" scope="row">1-4 hod</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{data.rentelItem.data.attributes.price[0].cena}</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{priceWithDPH(data.rentelItem.data.attributes.price[0].cena)}</th>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <th className="table-active" scope="row">1-3 dny</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{data.rentelItem.data.attributes.price[1].cena}</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{priceWithDPH(data.rentelItem.data.attributes.price[1].cena)}</th>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <th className="table-active" scope="row">4-14 dní</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{data.rentelItem.data.attributes.price[2].cena}</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{priceWithDPH(data.rentelItem.data.attributes.price[2].cena)}</th>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <th className="table-active" scope="row">15 a více</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{data.rentelItem.data.attributes.price[3].cena}</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{priceWithDPH(data.rentelItem.data.attributes.price[3].cena)}</th>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr>
                                    <th className="table-active" scope="row">víkend</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{data.rentelItem.data.attributes.price[4].cena}</th>
                                    <th style={{color: "#f8b600"}}
                                        scope="row">{priceWithDPH(data.rentelItem.data.attributes.price[4].cena)}</th>
                                </tr>
                                </tbody>

                            </table>
                        </div>
                        <ContactForm isWithDays={true}/>
                        {data.rentelItem.data.attributes.parameters.length !== 0 ?
                            <div className="m-5">

                                <h3>Parametry</h3>

                                <table className="table table-dark">
                                    {data.rentelItem.data.attributes.parameters.map((item, index) => (
                                        <tbody key={index}>
                                        <tr>
                                            <th scope="row">{item.nazev}</th>
                                            <th scope="row">{item.hodnota}</th>

                                        </tr>
                                        </tbody>
                                    ))}

                                </table>
                            </div> : ''
                        }


                    </div>

                    {data.rentelItem.data.attributes.youtubeId !== null ?
                        <YoutubeVideo videoId={data.rentelItem.data.attributes.youtubeId}/> : ''}

                </div>
            </div>
        </div>

    )
}